import React from 'react';
import ContactForm from './ContactForm';
import CoverImage from './CoverContact';
import LineQr from './images/lineqr.png'
import { Helmet } from 'react-helmet';

function Contact() {
  return (
    <div style={{
      marginTop: 50,
      marginBottom: 80,
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center',
      textAlign: 'center', 
      flexDirection: 'column',
      padding: 20
      }}>
        <CoverImage />
      <Helmet>
      <title>ติดต่อเรา | ผลิตและจำหน่าย หินแกรนิต ครกหินอ่างศิลา</title>
      <meta name='description' content='บริษัท รุ่งเรืองศิลาทิพย์ จำกัด เราผลิตและจำหน่าย หินแกรนิต ครกหิน ครกหินอ่างศิลา ช้างหิน สิงห์หินแกรนิต กิเลนหินแกรนิต ปี่เซี๊ยะหินแกรนิต อ่างหิน ตะเกียงหิน หินจัดสวน หินมงคล ลูกนิมิตหินแกรนิต ศาลพระภูมิหินแกรนิต สะพานหิน หินตกแต่งบ้าน หินตกแต่งภายใน หินตกแต่งโรงแรม หินตกแต่งร้านอาหาร และงานหินแกะสลักทุกรูปแบบ ทั้งเล็กและใหญ่' />
      <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/rungreangsilatip.appspot.com/o/ProductImage%2Fsculpture%2Fsculture11.jpg?alt=media&token=f1170c3a-1473-41ae-9845-cc77666bb4d4" />
      </Helmet>
        <h1>
        ติดต่อเรา
      </h1>
      <h3>
        บริษัท รุ่งเรืองศิลาทิพย์ จำกัด
      </h3>
      <p>ที่อยู่: 9/76 ตำบลเสม็ด อำเภอเมืองชลบุรี จังหวัดชลบุรี 20000</p>
      <p>อีเมล: silathip2013@gmail.com</p>
      <h4>
      โทรศัพท์: 038-398114, 096-291-9962
      </h4>
        <h4 
    style={{
      backgroundColor: 'black', 
      color: 'white', 
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 15,
      paddingRight: 15,  
      borderRadius: 10,
      marginBottom: 30,
      cursor: 'pointer'
    }}
    onClick={() => {
      window.open('https://lin.ee/jLB82AU', '_blank');
    }}
  >
    LINE
  </h4>
    <div>
    <img src={LineQr} alt="Line QR CODE" style={{height: "300px", width: '300px'}} onClick={() => {
          window.open('https://lin.ee/jLB82AU', '_blank');
        }}/>
    </div>
    <div style={{marginTop: 50}}>
  <h1>
          แบบฟอร์ม
        </h1>
    <ContactForm />
  </div>
      </div>
  );
}

export default Contact;