import React from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import StorefrontIcon from '@mui/icons-material/Storefront';
import FactoryIcon from '@mui/icons-material/Factory';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import './fonts.css'

function Body5() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const messages = [
    { icon: <StorefrontIcon fontSize="large" color="action" />, text: "เรามีหน้าร้าน" },
    { icon: <FactoryIcon fontSize="large" color="action" />, text: "มีโรงงานและเหมือง" },
    { icon: <LocalShippingIcon fontSize="large" color="action" />, text: "จัดส่งทั่วไทยและต่างประเทศ" },
    { icon: <DesignServicesIcon fontSize="large" color="action" />, text: "รับสั่งทำตามรูปแบบ" },
    { icon: <QuestionAnswerIcon fontSize="large" color="action" />, text: "สามารถให้คำปรึกษา" }
  ];

  return (
    <Grid container spacing={3} direction={isMobile ? 'column' : 'row'} alignItems="center" justifyContent="center">
      {messages.map((message, index) => (
        <Grid item xs={12} md={2} key={index} style={{ textAlign: 'center', marginTop: 20 }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            {message.icon}
            <Typography variant="body1" style={{ marginTop: 20 }}>
              {message.text}
            </Typography>
          </div>
        </Grid>
      ))}
    </Grid>
  );
}

export default Body5;
