import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ShowcaseHeader from './ShowcaseHeader'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function Showcase() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  return (
    <div style={{ marginTop: 70 }}>
      <Helmet>
      <title>รวมผลงานหินจากเราที่ลูกค้าไว้วางใจ | ผลิตและจำหน่าย หินแกรนิต ครกหินอ่างศิลา</title>
      <meta name='description' content='บริษัท รุ่งเรืองศิลาทิพย์ จำกัด เราผลิตและจำหน่าย หินแกรนิต ครกหิน ครกหินอ่างศิลา ช้างหิน สิงห์หินแกรนิต กิเลนหินแกรนิต ปี่เซี๊ยะหินแกรนิต อ่างหิน ตะเกียงหิน หินจัดสวน หินมงคล ลูกนิมิตหินแกรนิต ศาลพระภูมิหินแกรนิต สะพานหิน หินตกแต่งบ้าน หินตกแต่งภายใน หินตกแต่งโรงแรม หินตกแต่งร้านอาหาร และงานหินแกะสลักทุกรูปแบบ ทั้งเล็กและใหญ่' />
      <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/rungreangsilatip.appspot.com/o/ProductImage%2Flatern%2Flatern11.jpg?alt=media&token=19a1a3ee-437d-403d-8589-027f87d20bf8" />
      </Helmet>
    <ShowcaseHeader />
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding: 10 }}>
      <ImageList
        sx={{ width: 700, height: "100%" }}
        gap={5}
        cols={isMobile ? 1 : 2} 
      >
        {itemData.map((item) => (
          <ImageListItem key={item.img} sx={{ marginTop: 1, marginBottom: 2 }}>
            <img
              style={{ cursor: 'pointer' }}
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.img}?w=248&fit=crop&auto=format`}
              alt={item.title}
              loading="lazy"
              onClick={() => navigate(item.link)} 
            />
            <ImageListItemBar
              title={<span>สถานที่: {item.title} </span>}
              subtitle={<span>งานหินโดย: {item.author}</span>}
              position="below"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  </div>
);
}

const itemData = [
  {
    img: 'https://firebasestorage.googleapis.com/v0/b/rungreangsilatip.appspot.com/o/showcase%2FJoaliMaldives%2FB5E170A7-947B-4533-93D0-5971899A8FCA.jpg?alt=media&token=dd0ba69e-699b-404b-9279-c172f83e381b',
    title: 'รีสอร์ต Joali Maldives, ร้านอาหาร Saoke',
    author: 'รุ่งเรืองศิลาทิพย์',
    link: '/showcase/saoke'
  },
  {
    img: 'https://firebasestorage.googleapis.com/v0/b/rungreangsilatip.appspot.com/o/showcase%2FPerlBangkok%2FD8534280-C990-48FC-A0AE-A516C4F7B0E6.jpg?alt=media&token=9fd83132-6ada-4e40-ba2d-4b9540baa166',
    title: 'ศาลพระภูมิ ตึก Perl Bangkok',
    author: 'รุ่งเรืองศิลาทิพย์',
    link: '/showcase/perl'
  },
  {
    img: 'https://firebasestorage.googleapis.com/v0/b/rungreangsilatip.appspot.com/o/showcase%2FBacchus%2F32703C4D-DB2A-46F0-BFFB-B81F64D343FA.jpg?alt=media&token=bd74e219-ee15-4582-afe7-754a32b65e12&_gl=1*jenx4d*_ga*MTMxMjAwODQyNS4xNjk3ODA2NTQ3*_ga_CW55HF8NVT*MTY5ODAzMDE2Ni4xMC4xLjE2OTgwMzA1MjMuMTAuMC4w',
    title: 'ร้านอาหาร Salon Du Japonisant by Bacchus',
    author: 'รุ่งเรืองศิลาทิพย์',
    link: '/showcase/bacchus'
  },
  {
    img: 'https://firebasestorage.googleapis.com/v0/b/rungreangsilatip.appspot.com/o/showcase%2FPrivateResident%2FD91E52EC-5A4D-47DF-A94F-47F13698B30A.jpg?alt=media&token=1f662edb-066f-4547-a074-11a5d33d3888',
    title: 'Private Residence หาดวอนนภา',
    author: 'รุ่งเรืองศิลาทิพย์',
    link: '/showcase/residence'
  },
  {
    img: 'https://firebasestorage.googleapis.com/v0/b/rungreangsilatip.appspot.com/o/showcase%2FMaeFahluang%2FMaefahluang.jpg?alt=media&token=15095015-f090-4aa9-bc94-39da5b93f314',
    title: 'มหาวิทยาลัยแม่ฟ้าหลวง จังหวัดเชียงราย',
    author: 'รุ่งเรืองศิลาทิพย์',
    link: '/showcase/maefahluang'
  },
  {
    img: 'https://firebasestorage.googleapis.com/v0/b/rungreangsilatip.appspot.com/o/showcase%2FMixedWork%2F12631548_1210080252353292_7240517142346739229_n.jpg?alt=media&token=4a74d9f2-2ff7-41de-9739-c931353f09dd',
    title: 'รวมงานป้ายหิน ตามสถานที่ต่างๆ',
    author: 'รุ่งเรืองศิลาทิพย์',
    link: '/showcase/mixed'
  },
];