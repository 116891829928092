import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AppHeader from './AppHeader';
import Home from './Home';
import Product from './Product';
import ProductLamp from './ProductLamp';
import ProductHomeDeco from './ProductHomeDeco';
import ProductBasin from './ProductBasin';
import ProductBoulder from './ProductBoulder';
import ProductSculpture from './ProdcutSculpture';
import ProductSurface from './ProductSurface';
import Showcase from './Showcase';
import ShowcaseSaoke from './Showcase1';
import ShowcasePerl from './showcase2';
import ShowcaseBacchus from './Showcase3';
import ShowcaseResident from './Showcase4';
import ShowcaseMaefahluang from './Showcase5';
import ShowcaseMixed from './Showcase6';
import Contact from './Contact';
import PageNotFound from './PageNotFound';
import LoginAdmin from './LoginAdmin';
import Dashboard from './Dashboard';
import Inbox from './Inbox';
import BlogEditor from './BlogEditor';
import CreateBlogs from './createBlog'
import InboxDetail from './InboxDetail';
import Blogs from './blogs';
import BlogView from './blogview'
import EditingBlog from './BlogEdit';
import Footer from './Footer';
import AdminNav from './AdminNav';
import loadingGif from './images/Typing.gif';
import theme from './theme';
import { ThemeProvider } from '@mui/material';
import './fonts.css'

function MainLayout({ children }) {
  return (
    <>
      <AppHeader />
      {children}
      <Footer />
    </>
  );
}

function AdminDashboard({ children }) {
  return (
    <>
    <AdminNav />
    {children}
    <Dashboard />
    </>
  )
}

function AdminInbox({ children }) {
  return (
    <>
    <AdminNav />
    {children}
    <Inbox />
    </>
  )
}

function AdminBlogEditor({ children }) {
  return (
    <>
    <AdminNav />
    {children}
    <BlogEditor />
    </>
  )
}

function AdminInboxDetail({ children }) {
  return (
    <>
    <AdminNav />
    {children}
    <InboxDetail />
    </>
  )
}

function AdminCreateBlogs({ children }) {
  return (
    <>
    <AdminNav />
    {children}
    <CreateBlogs />
    </>
  )
}

function AdminEditBlog ({ children }) {
  return (
    <>
    <AdminNav />
    {children}
    <EditingBlog />
    </>
  )
}

function App() {
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 2000);
  
      return () => clearTimeout(timer);
    }, []);
  
    if (isLoading) {
      return (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.5)'
        }}>
         <img src={loadingGif} alt="Loading..." style={{width: 50, height: 'auto'}} />
        </div>
      );
    }
    return (
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/loginadmin" element={<LoginAdmin />} />
            <Route path="/dashboard" element={<AdminDashboard />} />
            <Route path="/inbox" element={<AdminInbox />} />
            <Route path="/blogeditor" element={<AdminBlogEditor />} />
            <Route path="/createblogs" element={<AdminCreateBlogs />} />
            <Route path="/inbox/:id" element={<AdminInboxDetail />} />
            <Route path="/editblog/:blogId" element={<AdminEditBlog />} />
            <Route path="*" element={
              <MainLayout>
                <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/product" element={<Product />} />
                <Route path="/product/productlamp" element={<ProductLamp />} />
                <Route path="/product/producthomedeco" element={<ProductHomeDeco />} />
                <Route path="/product/productbasin" element={<ProductBasin />} />
                <Route path="/product/productboulder" element={<ProductBoulder />} />
                <Route path="/product/productsculpture" element={<ProductSculpture />} />
                <Route path="/product/productsurface" element={<ProductSurface />} />
                <Route path="/showcase" element={<Showcase />} />
                <Route path="/showcase/saoke" element={<ShowcaseSaoke />} />
                <Route path="/showcase/perl" element={<ShowcasePerl />} />
                <Route path="/showcase/bacchus" element={<ShowcaseBacchus />} />
                <Route path="/showcase/residence" element={<ShowcaseResident />} />
                <Route path="/showcase/maefahluang" element={<ShowcaseMaefahluang />} />
                <Route path="/showcase/mixed" element={<ShowcaseMixed />} />
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/blogview/:id" element={<BlogView />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="*" element={<PageNotFound />} />
                </Routes>
              </MainLayout>
            } />
          </Routes>
        </Router>
      </ThemeProvider>
    );
  }

export default App;