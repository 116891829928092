import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Typography, Modal, Box, IconButton } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import "./fonts.css";

import image1 from "./images/cover/cover01.jpg";
import image2 from "./images/cover/cover02.jpg";
import image3 from "./images/cover/cover03.jpg";
import image4 from "./images/cover/cover04.jpg";
import image5 from "./images/cover/cover05.jpg";
import image6 from "./images/cover/cover06.jpg";
import image7 from "./images/cover/text.jpg"

function Body1() {
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const slides = [
    { src: image1, description: "ตกแต่งร้านอาหาร" },
    { src: image2, description: "ป้ายแกะสลัก" },
    { src: image3, description: "ประติมากรรม" },
    { src: image4, description: "ตกแต่งสวนสไตร์ญี่ปุ่น" },
    { src: image5, description: "อ่างหินธรรมชาติ"},
    { src: image6, description: "ศาลพระภูมิ"},
    { src: image7, description: "งานแกะสลักตัวหนังสือจีน"}
  ];

  const imgStyle = {
    width: "100%",
    height: "auto",
    maxWidth: "700px",
    display: "block",
    margin: "0 auto",
  };

  const textBoxStyle = {
    padding: "20px",
    textAlign: "center",
    position: "relative",
    marginTop: "20px",
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: (current, next) => setCurrentIndex(next),
  };

  const handleOpen = (index) => {
    setCurrentIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ position: "relative", width: "85%", paddingBottom: "30px" }}>
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index}>
            <img
              src={slide.src}
              alt={slide.description}
              style={imgStyle}
              onClick={() => handleOpen(index)}
            />
            <div style={textBoxStyle}>
              <Typography
                variant="h6"
                component="h2"
                style={{ color: "black" }}
              >
                {slide.description}
              </Typography>
            </div>
          </div>
        ))}
      </Slider>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            p: 4,
          }}
        >
          <IconButton onClick={() => setCurrentIndex((currentIndex - 1 + slides.length) % slides.length)} style={{ position: "absolute", left: 0, top: "50%", transform: "translateY(-50%)", color: 'black' }}>
            <ArrowBackIosIcon />
          </IconButton>
          <img src={slides[currentIndex].src} alt="Full Size" style={{ maxWidth: "90vw", maxHeight: "90vh" }} />
          <IconButton onClick={() => setCurrentIndex((currentIndex + 1) % slides.length)} style={{ position: "absolute", right: 0, top: "50%", transform: "translateY(-50%)", color: 'black' }}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
      </Modal>
    </div>
  );
}

export default Body1;
