import { useEffect, useState } from 'react';
import { collection, query, getDocs, orderBy } from "firebase/firestore";
import { Link } from "react-router-dom";
import { db } from './firebaseConfig';
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';

function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const maxCharacters = 150;

  useEffect(() => {
    const fetchBlogs = async () => {
      const blogsQuery = query(collection(db, 'blogs'), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(blogsQuery);
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });
      setBlogs(data);
    };

    fetchBlogs();
  }, []);

  return (
<div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: 5,
      marginTop: 65,
    }}>
      <Helmet>
      <title>บทความ | ผลิตและจำหน่าย หินแกรนิต ครกหินอ่างศิลา</title>
      <meta name='description' content='บริษัท รุ่งเรืองศิลาทิพย์ จำกัด เราผลิตและจำหน่าย หินแกรนิต ครกหิน ครกหินอ่างศิลา ช้างหิน สิงห์หินแกรนิต กิเลนหินแกรนิต ปี่เซี๊ยะหินแกรนิต อ่างหิน ตะเกียงหิน หินจัดสวน หินมงคล ลูกนิมิตหินแกรนิต ศาลพระภูมิหินแกรนิต สะพานหิน หินตกแต่งบ้าน หินตกแต่งภายใน หินตกแต่งโรงแรม หินตกแต่งร้านอาหาร และงานหินแกะสลักทุกรูปแบบ ทั้งเล็กและใหญ่' />
      </Helmet>
      <div style={{ width: '80%', maxWidth: 800 }}> 
        <h1 style={{ textAlign: 'center' }}>บทความ</h1>
        {blogs.length === 0 ? (
          <p>ยังไม่มีบทความให้แสดง</p>
        ) : (
          blogs.map(blog => (
            <div key={blog.id} style={{
              marginBottom: 20,
              padding: 10,
              border: '1px solid #ddd',
              borderRadius: 5,
              backgroundColor: '#f9f9f9' 
            }}>
              <Link to={`/blogview/${blog.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Box
                  component="img"
                  sx={{
                    maxHeight: { xs: 133, md: 250 },
                    display: 'block', 
                    margin: 'auto' 
                  }}
                  alt="cover"
                  src={blog.cover}
                />
                <p style={{fontWeight: 700}}>{blog.subject}</p>
                <p style={{fontWeight: 400}}>
                  {blog.details.length > maxCharacters
                    ? `${blog.details.substring(0, maxCharacters)}...`
                    : blog.details}
                </p>
                <p style={{fontWeight: 200}}>ผู้เขียน: {blog.writer}</p>
                <p style={{fontWeight: 200}}>เวลา: {blog.timestamp.toDate().toLocaleString()}</p>
              </Link>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default Blogs;