import React, {useState, useEffect} from 'react';
import { storage } from './firebaseConfig'
import { ref, listAll, getDownloadURL } from 'firebase/storage';
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogContent,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const getProductImages = async () => {
  const storageRef = ref(storage, "ProductImage/sculpture");
  const fileRefs = await listAll(storageRef);

  const downloadURLs = await Promise.all(
    fileRefs.items.map(fileRef => getDownloadURL(fileRef))
  );

  return downloadURLs;
};

function Product5() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      const images = await getProductImages();
      setProducts(images.map(src => ({ src })));
    };
    
    fetchImages();
  }, []);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseImage = () => {
    setSelectedImage(null);
  };

  return (
    <Grid container spacing={2} >
    {products.map((product, index) => (
      <Grid item xs={6} md={4} key={index}>
        <Card elevation={0}>
          <CardContent>
            <CardMedia
              component="img"
              src={product.src}
              alt={`Product ${index + 1}`}
              style={{
                width: '100%',
                aspectRatio: "1/1",
                objectFit: "contain",
                cursor: 'pointer',
              }}
              onClick={() => handleImageClick(product.src)}
            />
          </CardContent>
        </Card>
      </Grid>
    ))}
    <Dialog open={!!selectedImage} onClose={handleCloseImage} maxWidth="lg">
      <DialogContent>
        <img
          src={selectedImage}
          alt="Full Screen"
          style={{ width: '100%', maxHeight: '80vh', objectFit: 'contain' }}
        />
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleCloseImage}
          aria-label="close"
          style={{ position: 'absolute', top: 0, right: 5 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogContent>
    </Dialog>
  </Grid>
);
}

export default Product5;