import React from 'react';
import { Grid, Typography } from '@mui/material';
import image1 from './images/Picture4.jpg';
import image2 from './images/rrcover.jpg';
import image3 from './images/Picture5.jpg';
import './fonts.css';

function Body2() {
  const imageData = [
    { src: image1,  ThaiDescription: "ด้วยอุปกรณ์ที่ทันสมัยและบุคลากรที่มีทักษะ เราสกัดวัสดุหินที่ดีที่สุดอย่างสม่ำเสมอโดยไม่ก่อให้เกิดความเสียหายตลอดกระบวนการทั้งหมด" },
    { src: image2,  ThaiDescription: "วัสดุหินของเราได้มาจากเหมืองชั้นนำในภาคเหนือของประเทศไทย ซึ่งเราเป็นเจ้าของโดยตรง" },
    { src: image3,  ThaiDescription: "ทักษะด้านช่างฝีมือของเราได้รับการสืบทอดจากรุ่นสู่รุ่น จึงรับประกันได้ถึงงานฝีมือระดับแนวหน้าในด้านงานศิลปะหิน" },
  ];

  const cardStyle = {
    padding: '10px',
    margin: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  };

  const imageStyle = {
    width: '99%',
    height: '99%',
    objectFit: 'cover'
  };

  return (
    <Grid container spacing={2} style={{ justifyContent: 'center', marginBottom: 50, padding: 10 }}>
    {imageData.map((item, index) => (
      <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
        <div style={cardStyle}>
          <img src={item.src} alt={`Description ${index + 1}`} style={imageStyle} />
          <Typography variant="body1" component="h2" style={{ marginTop: '15px', padding: 10, textAlign:'center' }}>
            {item.ThaiDescription}
          </Typography>
        </div>
      </Grid>
    ))}
  </Grid>
);
}

export default Body2;