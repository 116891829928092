import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, getDocs, orderBy, deleteDoc, doc } from "firebase/firestore";
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, IconButton } from '@mui/material';
import { Link } from "react-router-dom";
import { db } from './firebaseConfig';
import Box from '@mui/material/Box';

function BlogEditor() {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const maxCharacters = 220;

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/loginadmin');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const fetchBlogs = async () => {
      const blogsQuery = query(collection(db, 'blogs'), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(blogsQuery);
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });
      setBlogs(data);
    };

    fetchBlogs();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm('แน่ใจไหมจ๊ะ ว่าจะลบโพสนี้')) {
      try {
        await deleteDoc(doc(db, 'blogs', id));
        setBlogs(blogs.filter(blog => blog.id !== id));
      } catch (error) {
        console.error('Error deleting document: ', error);
        alert('There was an error deleting the blog post. Please try again.');
      }
    }
  };

  return (
    <div style={{ marginTop: 100, alignItems: 'center', justifyContent: 'center', flexDirection: 'row', padding: 5 }}>
      <Button
        startIcon={<EditIcon />}
        variant="contained"
        color="secondary"
        onClick={() => navigate('/createblogs')}
      >
        + สร้างบทความใหม่
      </Button>
      <div style={{marginTop: 25}}>
        {blogs.length === 0 ? (
          <p>ยังไม่มีบทความให้แสดง</p>
        ) : (
          blogs.map(blog => (
            <div key={blog.id} style={{ marginBottom: 20, padding: 10, border: '1px solid #ddd', borderRadius: 5 }}>
              <Link to={`/blogview/${blog.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
              <Box
              component="img"
               sx={{
               height: 233,
               maxHeight: { xs: 233, md: 167 },
                }}
                alt="cover"
                 src={blog.cover}
                 />
                <p>หัวเรื่อง: {blog.subject}</p>
                <p>
                  {blog.details.length > maxCharacters
                    ? `${blog.details.substring(0, maxCharacters)}...`
                    : blog.details}
                </p>
                <p>เวลา: {blog.timestamp.toDate().toLocaleString()}</p>
              </Link>
              <div style={{ display: 'flex', justifyContent: 'right', marginTop: 10 }}>
              <IconButton
                  color="primary"
                  onClick={() => navigate(`/editblog/${blog.id}`)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  color="secondary"
                  onClick={() => handleDelete(blog.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default BlogEditor;