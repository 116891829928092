import React, { useState, useEffect, useCallback } from 'react';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

function ScrollToTopButton() {
  const [visible, setVisible] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const handleScroll = useCallback(() => {
    const checkScrollTop = () => {
      if (!visible && window.pageYOffset > 400) {
        setVisible(true);
      } else if (visible && window.pageYOffset <= 400) {
        setVisible(false);
      }
    };

    checkScrollTop();

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      setVisible(false);
    }, 5000);

    setTimeoutId(newTimeoutId);
  }, [timeoutId, visible]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div>
      {visible && (
        <ArrowCircleUpIcon
          onClick={scrollToTop}
          style={{
            fontSize: 40,
            color: 'white',
            backgroundColor: 'black',
            borderRadius: '50%',
            opacity: 0.5,
            position: 'fixed',
            bottom: '8%',
            right: '5%',
            cursor: 'pointer',
            zIndex: 1000,
          }}
        />
      )}
    </div>
  );
}

export default ScrollToTopButton;
