import React from 'react';
import { Typography } from '@mui/material';
import image1 from  './images/sculture05.jpg';
import './fonts.css'

function Body3({ onTextClick }) {
    const style = {
      backgroundImage: `url(${image1})`,
      backgroundSize: 'cover', 
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      height: '100vh', 
      width: '100%',    
      position: 'relative', 
    };
    const textBoxStyle = {
        padding: '5px',
        textAlign: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0)', 
        position: 'absolute', 
        top: '50%',  
        left: '50%',  
        transform: 'translate(-50%, -50%)',  
      };
  
      return (
        <div style={style}>
          <div style={textBoxStyle}>
            <Typography variant="h6" component="h6" style={{ color: 'white', cursor: 'pointer' }} onClick={onTextClick}>
         เราผลิต
            </Typography>
            <Typography variant="h6" component="h6" style={{ color: 'white', cursor: 'pointer', marginBottom: 25 }} onClick={onTextClick}>
         และจัดจำหน่าย
            </Typography>
            <Typography variant="h6" component="h6" style={{ color: 'white', cursor: 'pointer' }} onClick={onTextClick}>
         ผลิตภัณฑ์จาก
            </Typography>
            <Typography variant="h6" component="h6" style={{ color: 'white', cursor: 'pointer', marginBottom: 25 }} onClick={onTextClick}>
         "หินแกรนิต" ไทย
            </Typography>
            <Typography variant="h6" component="h6" style={{ color: 'white', cursor: 'pointer' }} onClick={onTextClick}>
         ทุกชนิด
            </Typography>
          </div>
        </div>
      );
    }
    
  
  export default Body3;