import React from 'react';
import { Typography } from '@mui/material';
import './fonts.css'

function ShowcaseHeader() {
    const style = {
      backgroundColor: 'white',
      backgroundSize: 'cover', 
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      height: '100px', 
      width: '100%',    
      position: 'relative', 
    };
    const textBoxStyle = {
        padding: '20px',
        border: '0px solid white',
        textAlign: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0)', 
        position: 'absolute', 
        top: '50%',  
        left: '50%',  
        transform: 'translate(-50%, -50%)',  
      };
  
      return (
        <div style={style}>
          <div style={textBoxStyle}>
            <Typography variant="h5" component="h6" style={{ color: 'black' }} >
         Showcase
            </Typography>
          </div>
        </div>
      );
    }
    
  
  export default ShowcaseHeader;