import React from 'react';

function PageNotFound() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <h1>404</h1>
      <p>Page Not Found</p>
    </div>
  );
}

export default PageNotFound;