import React, {useEffect} from "react";
import EditBlogForms from "./editBlogForm";
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig';
import { useNavigate } from "react-router-dom";

function EditingBlog() {
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (!user) {
            navigate('/loginadmin');
          }
        });
      
        return () => unsubscribe();
      }, [navigate]);

    return(
        <div style={{marginTop: 100, flexDirection: 'row', marginBottom: 100}}>
            <EditBlogForms />
        </div>
    )
}

export default EditingBlog;