import React, { useEffect } from 'react';
import {
  AppBar,
  Tabs,
  Tab,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItem,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useLocation } from 'react-router-dom';
import './AdminHeaderStyle.css';

function AppHeader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const [value, setValue] = React.useState(false); 
  const [mobileOpen, setMobileOpen] = React.useState(false);

  useEffect(() => {
    const tabRoutes = ['/dashboard', '/inbox', '/blogeditor'];
    const matches = tabRoutes.includes(location.pathname);
    setValue(matches ? location.pathname : false);
  }, [location.pathname]);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div>
      <AppBar position="fixed" sx={{ backgroundColor: 'white'}} >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1}}>
            <Link to="/dashboard" style={{ textDecoration: 'none', color: 'black' }}>
            Admin Console
            </Link>
          </Typography>
          {isMobile ? (
            <Typography component="div" onClick={handleDrawerToggle} style={{ fontSize: 30, marginBottom: 6, color: 'black' }}>
              ☰
            </Typography>
          ) : (
            <Tabs
              value={value}
              TabIndicatorProps={{ style: { backgroundColor: 'white' } }}>
              <Tab label="Dashboard" value="/dashboard" component={Link} to="/dashboard" />
              <Tab label="Inbox" value="/inbox" component={Link} to="/inbox" />
              <Tab label="Blogs Editor" value="/blogeditor" component={Link} to="/blogeditor" />
            </Tabs>
          )}

        </Toolbar>
      </AppBar>
      {isMobile && (
        <Drawer
          anchor="bottom"
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
            BackdropProps: {
              style: {
                backgroundColor: 'rgb(0, 0, 0)',
              },
            },
          }}
          PaperProps={{
            style: {
              backgroundColor: 'rgb(0, 0, 0)',
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            },
          }}
        >
          <IconButton onClick={handleDrawerToggle} style={{ position: 'absolute', top: 10, right: 10 }}>
            <CloseIcon style={{color: 'white'}} />
          </IconButton>
          <List style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <ListItem
              button
              component={Link}
              to="/dashboard"
              onClick={() => {
                setValue('/dashboard');
                handleDrawerToggle();
              }}
              style={{ width: '100%', justifyContent: 'center', fontSize: '25px', color: 'white' }}
            >
              Dashboard
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/inbox"
              onClick={() => {
                setValue('/inbox');
                handleDrawerToggle();
              }}
              style={{ width: '100%', justifyContent: 'center', fontSize: '25px', color: 'white' }}
            >
              Inbox
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/blogeditor"
              onClick={() => {
                setValue('/blogeditor');
                handleDrawerToggle();
              }}
              style={{ width: '100%', justifyContent: 'center', fontSize: '25px', color: 'white' }}
            >
              Blogs Editor
            </ListItem>
          </List>
        </Drawer>
      )}
    </div>
  );
}

export default AppHeader;