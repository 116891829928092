import React, { useState, useRef } from 'react';
import { Button, TextField, Grid, Container, useMediaQuery, useTheme, Chip, CircularProgress } from '@mui/material';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { readAndCompressImage } from 'browser-image-resizer';
import { useNavigate } from 'react-router-dom';
import "./fonts.css";

function BlogForm() {
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const coverInputRef = useRef(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [coverImage, setCoverImage] = useState(null);
  const [coverPreview, setCoverPreview] = useState(null);
  const [formData, setFormData] = useState({
    subject: '',
    writer: '',
    details: '',
    cover: '',
    images: []
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const remainingImagesCount = 8 - selectedImages.length;

  const handleCoverChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const imageConfig = {
      quality: 0.5,
      maxWidth: 800,
      maxHeight: 800,
      autoRotate: true,
      debug: true
    };

    try {
      const resizedImage = await readAndCompressImage(file, imageConfig);
      setCoverImage(resizedImage);

      const reader = new FileReader();
      reader.onloadend = () => {
        setCoverPreview(reader.result);
      };
      reader.readAsDataURL(resizedImage);
    } catch (error) {
      console.error("Error resizing the image: ", error);
    }
  };

  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length + selectedImages.length > 8) {
      alert('เลือกได้สูงสุด 8 ภาพ');
      return;
    }
    if (files.length > remainingImagesCount) {
      alert(`คุณสามารถเลือกได้ ${remainingImagesCount} รูปภาพ`);
      return;
    }

    const processedImages = [];
    const imagePreviews = [];

    for (let file of files) {
      const imageConfig = {
        quality: 0.5,
        maxWidth: 800,
        maxHeight: 800,
        autoRotate: true,
        debug: true
      };

      try {
        const resizedImage = await readAndCompressImage(file, imageConfig);
        processedImages.push(resizedImage);

        const reader = new FileReader();
        reader.onloadend = () => {
          imagePreviews.push(reader.result);
          setSelectedImages([...selectedImages, ...imagePreviews]);
        };
        reader.readAsDataURL(resizedImage);
      } catch (error) {
        console.error("Error resizing the image: ", error);
      }
    }

    setFormData(prevState => ({
      ...prevState,
      images: [...prevState.images, ...processedImages]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const storage = getStorage();
    const downloadURLs = [];

    let coverDownloadURL = null;

    if (coverImage) {
        const coverImageName = 'cover_' + Math.random().toString(36).substring(2, 15) + ".jpg";
        const coverStorageRef = ref(storage, 'blogs/' + coverImageName);
        const coverUploadTask = uploadBytesResumable(coverStorageRef, coverImage);

        await new Promise((resolve, reject) => {
            coverUploadTask.on('state_changed',
                () => {},
                (error) => {
                    console.error("Error uploading cover image: ", error);
                    reject(error);
                },
                async () => {
                    coverDownloadURL = await getDownloadURL(coverUploadTask.snapshot.ref);
                    resolve();
                }
            );
        });
    }

    for (let image of formData.images) {
        const imageName = Math.random().toString(36).substring(2, 15) + ".jpg";
        const storageRef = ref(storage, 'blogs/' + imageName);
        const uploadTask = uploadBytesResumable(storageRef, image);

        await new Promise((resolve, reject) => {
            uploadTask.on('state_changed',
                () => {},
                (error) => {
                    console.error("Error uploading image: ", error);
                    reject(error);
                },
                async () => {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    downloadURLs.push(downloadURL);
                    resolve();
                }
            );
        });
    }

    const contactsRef = collection(db, 'blogs');
    try {
        await addDoc(contactsRef, {
            ...formData,
            cover: coverDownloadURL,
            images: downloadURLs,
            timestamp: serverTimestamp()
        });

        console.log("Document successfully written!");
        setFormData({
            subject: '',
            writer: '',
            details: '',
            cover: '',
            images: []
        });
        setSelectedImages([]);
        setCoverImage(null);
        setCoverPreview(null);
        alert("สร้างบทความเรียบร้อย หวังว่าจะมีคนเข้ามาอ่านเยอะๆนะจ๊ะ");
        navigate('/blogeditor');
    } catch (error) {
        console.error("Error writing document: ", error);
        alert("There was an error submitting the form. Please try again.");
    } finally {
        setLoading(false);
    }
};

  const handleDeselectImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);

    const updatedFormDataImages = [...formData.images];
    updatedFormDataImages.splice(index, 1);
    setFormData(prevState => ({
        ...prevState,
        images: updatedFormDataImages  
    }));

    if (fileInputRef.current) {
        fileInputRef.current.value = '';
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
    }
  };

  return (
    <Container maxWidth={isMobile ? 'xl' : 'lg'}>
      <form onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              fullWidth
              required
              label="หัวเรื่อง"
              name="subject"
              variant="outlined"
              value={formData.subject}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              required
              label="บทความ"
              name="details"
              variant="outlined"
              size="medium"
              multiline
              rows={20}
              inputProps={{ maxLength: 10000 }}
              value={formData.details}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              sx={{ "& .MuiInputBase-input": { fontSize: 15, padding: 1 } }}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              required
              label="ผู้เขียนบทความ"
              name="writer"
              variant="outlined"
              value={formData.writer}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', position: 'relative' }}>
              <input
                id="coverInput"
                type="file"
                onChange={handleCoverChange}
                ref={coverInputRef}
                style={{
                  opacity: 0,
                  position: 'absolute',
                  zIndex: 1,
                  width: '100%',
                  height: '100%',
                }}
              />
              <label
                htmlFor="coverInput"
                style={{
                  display: 'block',
                  padding: '10px 15px',
                  background: 'black',
                  color: 'white',
                  cursor: 'pointer',
                  borderRadius: '5px',
                }}
              >
                อัปโหลดภาพหน้าปก
              </label>
            </div>
            {coverPreview && (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 5 }}>
                <img src={coverPreview} alt="Cover Preview" style={{ width: '100px', height: '100px', marginBottom: 5 }} />
                <Chip label="ลบ

" variant="filled" onClick={() => { setCoverImage(null); setCoverPreview(null); }} />
              </div>
            )}
          </Grid>
          <Grid item>
            <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', position: 'relative' }}>
              <input
                id="imageInput"
                type="file"
                multiple
                onChange={handleImageChange}
                ref={fileInputRef}
                style={{
                  opacity: 0,
                  position: 'absolute',
                  zIndex: 1,
                  width: '100%',
                  height: '100%',
                }}
              />
              <label
                htmlFor="imageInput"
                style={{
                  display: 'block',
                  padding: '10px 15px',
                  background: 'black',
                  color: 'white',
                  cursor: 'pointer',
                  borderRadius: '5px',
                }}
              >
                เลือกได้ {remainingImagesCount} รูปภาพ
              </label>
            </div>
            {selectedImages.map((image, index) => (
              <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 5 }}>
                <img src={image} alt={`Selected Preview ${index}`} style={{ width: '100px', height: '100px', marginBottom: 5 }} />
                <Chip label="ลบ" variant="filled" onClick={() => handleDeselectImage(index)} />
              </div>
            ))}
          </Grid>
          <Grid item>
            {loading && (
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <CircularProgress />
              </div>
            )}
          </Grid>
          <Grid item>
            <Button variant="outlined" color="inherit" type="submit" disabled={loading}>
              สร้างบทความ
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default BlogForm;