import React, { useRef, useState } from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import ScrollToTopButton from './scrollToTopButton';
import Body1 from './body1';
import Body2 from './body2';
import Body3 from './body3';
import Body4 from './body4';
import Body5 from './body5';
import LineQr from './images/lineqr.png'
import './fonts.css';
import { Helmet } from 'react-helmet';

function Home() {
  const body2Ref = useRef(null);
  const [animateText, setAnimateText] = useState(false);

  const scrollToBody2 = () => {
    body2Ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  const onEnterViewport = () => {
    setAnimateText(true);
  };

  const onExitViewport = () => {
    setAnimateText(false);
  };

  return (
    <div>
      <Helmet>
      <title>บริษัท รุ่งเรืองศิลาทิพย์ จำกัด | ผลิตและจำหน่าย หินแกรนิต ครกหินอ่างศิลา</title>
      <meta name='description' content='บริษัท รุ่งเรืองศิลาทิพย์ จำกัด เราผลิตและจำหน่าย หินแกรนิต ครกหิน ครกหินอ่างศิลา ช้างหิน สิงห์หินแกรนิต กิเลนหินแกรนิต ปี่เซี๊ยะหินแกรนิต อ่างหิน ตะเกียงหิน หินจัดสวน หินมงคล ลูกนิมิตหินแกรนิต ศาลพระภูมิหินแกรนิต สะพานหิน หินตกแต่งบ้าน หินตกแต่งภายใน หินตกแต่งโรงแรม หินตกแต่งร้านอาหาร และงานหินแกะสลักทุกรูปแบบ ทั้งเล็กและใหญ่' />
      <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/rungreangsilatip.appspot.com/o/ProductImage%2Fsculpture%2Fsculture07.jpg?alt=media&token=c8ac7c42-3aa8-4b6e-a69d-ddecc6270ad0" />
      </Helmet>
    <div >
      <Body3 onTextClick={scrollToBody2} />
    </div>
    <div style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        marginTop: 50,
        marginBottom: 20,
        padding: 20
      }}>
        <h1>"</h1>
        <ScrollTrigger onEnter={onEnterViewport} onExit={onExitViewport}>
          <h3 style={{
            transform: animateText ? 'translateX(0)' : 'translateX(-100vw)',
            transition: 'transform 1s',
          }}>
            เราผลิตและจัดจำหน่ายผลิตภัณฑ์จากหินแกรนิตทุกชนิด ตั้งแต่ขนาดเล็กไปจนถึงประติมากรรมแกะสลักขนาดใหญ่
          </h3>
          <h3 style={{
            transform: animateText ? 'translateX(0)' : 'translateX(-100vw)',
            transition: 'transform 1s',
            delay: '0.5s',
          }}>
            สินค้าของเราผลิตจากหินแกรนิตคัดเกรดจากเหมืองจังหวัดตากของเราเอง โดยช่างผู้ชำนาญการประสบการณ์มากกว่า 10 ปี
          </h3>
        </ScrollTrigger>
        <h1>"</h1>
      </div>
    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', textAlign: 'center'}}>
      <h1 style={{padding: 20}}>ด้วยประสบการณ์ และอุปกรณ์ครบครัน</h1>
    </div>
    <div ref={body2Ref}>
      <Body2 />
    </div>
    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 20, textAlign: 'center'}}>
      <h1 style={{padding: 20}}>เรามีความเชี่ยวชาญในงานหินทุกรูปแบบ</h1>
    </div>
    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: 40}}>
      <Body1 />
    </div>
    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', textAlign: 'center'}}>
      <h1 style={{padding: 20, marginTop: 20, bottom: 0}}>ครกหินอ่างศิลา</h1>
    </div>
    <div>
      <Body4 />
    </div>
    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', textAlign: 'center'}}>
      <h1 style={{padding: 20, marginTop: 20, bottom: 0}}>การบริการ</h1>
    </div>
    <div style={{marginBottom: 80}}>
      <Body5 />
    </div>
    <div style={{
    marginTop: 20,
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center',
    textAlign: 'center', 
    flexDirection: 'column',
    padding: 20
    }}>
<h1>
        ติดต่อเรา
      </h1>
      <h3>
        บริษัท รุ่งเรืองศิลาทิพย์ จำกัด
      </h3>
      <p>ที่อยู่: 9/76 ตำบลเสม็ด อำเภอเมืองชลบุรี จังหวัดชลบุรี 20000</p>
      <p>อีเมล: silathip2013@gmail.com</p>
      <h4>
      โทรศัพท์: 038-398114, 096-291-9962
      </h4>
      <h4 
  style={{
    backgroundColor: 'black', 
    color: 'white', 
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,  
    borderRadius: 10,
    cursor: 'pointer'
  }}
  onClick={() => {
    window.open('https://lin.ee/jLB82AU', '_blank');
  }}
>
  LINE
</h4>
    </div>
    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: 80}}>
        <img src={LineQr} alt="Line QR CODE" style={{height: "300px", width: '300px'}} onClick={() => {
          window.open('https://lin.ee/jLB82AU', '_blank');
        }}/>
    </div>
   <ScrollToTopButton />
  </div>
);}

export default Home;