import * as React from 'react';
import { useState, useEffect } from 'react';
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Helmet } from 'react-helmet';

export default function ShowcaseMixed() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const storage = getStorage();
    const imageRefs = ref(storage, 'showcase/MixedWork');

    listAll(imageRefs).then((res) => {
      const urlPromises = res.items.map(imageRef => 
        getDownloadURL(imageRef)
      );

      Promise.all(urlPromises).then(urls => {
        const imageData = urls.map(url => ({
          img: url,
        }));

        setImages(imageData);
      });
    });
  }, []);

  return (
    <div style={{marginTop: 80}}>
      <Helmet>
      <title>รวมงานป้ายหิน ตามสถานที่ต่างๆ by รุ่งเรืองศิลาทิพย์ | ผลิตและจำหน่าย หินแกรนิต ครกหินอ่างศิลา</title>
      <meta name='description' content='บริษัท รุ่งเรืองศิลาทิพย์ จำกัด เราผลิตและจำหน่าย หินแกรนิต ครกหิน ครกหินอ่างศิลา ช้างหิน สิงห์หินแกรนิต กิเลนหินแกรนิต ปี่เซี๊ยะหินแกรนิต อ่างหิน ตะเกียงหิน หินจัดสวน หินมงคล ลูกนิมิตหินแกรนิต ศาลพระภูมิหินแกรนิต สะพานหิน และงานหินแกะสลักทุกรูปแบบ ทั้งเล็กและใหญ่' />
      </Helmet>
      <div style={{display: 'flex', textAlign: 'center', justifyContent: 'center', flexDirection: 'column'}}>
        <p style={{fontWeight: 200}}>   
          สถานที่: รวมงานป้ายหิน ตามสถานที่ต่างๆ
          </p>
          <div>
          <p>
          ป้ายหินแกะสลัก by รุ่งเรืองศิลาทิพย์
        </p>
        </div>
      </div>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding: 10, marginTop: 10, marginBottom: 50 }}>
      <Box sx={{ width: 800, height: "100%" }}>
        <ImageList variant="masonry" cols={2} gap={8}>
          {images.map((item, index) => (
            <ImageListItem key={item.img} >
              <img
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=248&fit=crop&auto=format`}
                loading="lazy"
                alt='SaokeSet'
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </div>
    </div>
  );
}