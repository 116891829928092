import React, { useEffect, useState } from 'react';
import { collection, query, getDocs, orderBy } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig';
import { Link } from "react-router-dom";
import { db } from './firebaseConfig';

function Inbox() {
  const [contacts, setContacts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/loginadmin');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const fetchContacts = async () => {
      const contactsQuery = query(collection(db, 'contacts'), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(contactsQuery);
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });
      setContacts(data);
    };

    fetchContacts();
  }, []);

  return (
    <div style={{ display: 'flex', alignContent: 'flex-start', flexDirection: 'column', padding: 20, marginTop: 50 }}>
      {contacts.length === 0 ? (
        <p>ยังไม่มีข้อความจากลูกค้า</p>
      ) : (
        contacts.map(contact => (
          <Link key={contact.id} to={`/inbox/${contact.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            <div style={{ marginBottom: 20, padding: 10 }}>
              <p>Name: {contact.name}</p>
              <p>Subject: {contact.subject}</p>
              <p>Timestamp: {contact.timestamp.toDate().toLocaleString()}</p>
            </div>
          </Link>
        ))
      )}
    </div>
  );
};

export default Inbox;
