import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Menu,
  Tabs,
  Popover, 
  Paper,
  Tab,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItem,
  IconButton,
  useMediaQuery,
  useTheme,
  MenuItem
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FacebookIcon from '@mui/icons-material/Facebook';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Link, useLocation } from 'react-router-dom';
import './AppHeaderStyle.css';
import rrsltlogo from './images/cover/rrslt.png'

function AppHeader() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const [value, setValue] = React.useState(false); 
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [productTabAnchorEl, setProductTabAnchorEl] = useState(null);
  const [isProductDropdownOpen, setIsProductDropdownOpen] = useState(false); 

  const handleProductTabClick = (event) => {
    setProductTabAnchorEl(event.currentTarget);
    setIsProductDropdownOpen(!isProductDropdownOpen); 
  };
  const handleProductTabClose = () => {
    setProductTabAnchorEl(null);
    setIsProductDropdownOpen(false);
  };
  useEffect(() => {
    const tabRoutes = ['/','/product', '/showcase', '/contact', '/blogs'];
    const matches = tabRoutes.includes(location.pathname);
    setValue(matches ? location.pathname : false);
  }, [location.pathname]);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div>
      <AppBar position="fixed" sx={{ backgroundColor: 'white'}} elevation={0}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1}} >
            <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
            <img src={rrsltlogo} alt="Logo" style={{height: 65, marginTop: 5}} />
            </Link>
          </Typography>
          {isMobile ? (
            <Typography component="div" onClick={handleDrawerToggle} style={{ fontSize: 30, marginBottom: 6, color: 'black' }}>
              ☰
            </Typography>
          ) : (
            <Tabs
              value={value}
              TabIndicatorProps={{ style: { backgroundColor: 'black' } }}>
              <Tab label="Home" value="/" component={Link} to="/" className="customTabColor" />
              <Tab
      label={
        <Box display="flex" alignItems="center" onClick={() => {
          navigate("/product");
        }}>
          Product
          <Box 
            component="span" 
            onClick={(e) => {
              e.stopPropagation();
              handleProductTabClick(e);
            }}
          >
            {isProductDropdownOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Box>
        </Box>
      }
      value="/product"
      aria-controls="product-menu"
      aria-haspopup="true"
    />
              <Tab label="Showcase" value="/showcase" component={Link} to="/showcase" />
              <Tab label="Blogs" value="/blogs" component={Link} to="/blogs" />
              <Tab label="Contact" value="/contact" component={Link} to="/contact" />
              <Tab
                icon={<FacebookIcon />}
                onClick={(event) => {
                  event.preventDefault();
                  window.open('https://www.facebook.com/r.silathip', '_blank');
                }}
              />
            </Tabs>
          )}
          <Menu
  id="product-menu"
  anchorEl={productTabAnchorEl}
  open={Boolean(productTabAnchorEl)}
  onClose={handleProductTabClose}
  elevation={0}
  anchorOrigin={{
    vertical: 'bottom',
    horizontal: 'center',
  }}
  transformOrigin={{
    vertical: 'top',
    horizontal: 'center',
  }}>
  <MenuItem
    component={Link}
    to="/product/productlamp"
    onClick={handleProductTabClose}>
    ตะเกียงหินญี่ปุ่น
  </MenuItem>
  <MenuItem
    component={Link}
    to="/product/producthomedeco"
    onClick={handleProductTabClose}>
    หินตกแต่งสถานที่
  </MenuItem>
  <MenuItem
    component={Link}
    to="/product/productbasin"
    onClick={handleProductTabClose}>
    อ่างหิน
  </MenuItem>
  <MenuItem
    component={Link}
    to="/product/productsculpture"
    onClick={handleProductTabClose}>
    งานประติมากรรมหิน
  </MenuItem>
  <MenuItem
    component={Link}
    to="/product/productboulder"
    onClick={handleProductTabClose}>
    ป้ายหินแกะสลัก
  </MenuItem>
  <MenuItem
    component={Link}
    to="/product/productsurface"
    onClick={handleProductTabClose}>
    งานกำแพง ผนัง และพื้นผิว
  </MenuItem>
</Menu>
        </Toolbar>
      </AppBar>
      {isMobile && (
        <Drawer
          anchor="top"
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
            BackdropProps: {
              style: {
                backgroundColor: 'rgba(255, 255, 255, 0.3)',
              },
            },
          }}
          PaperProps={{
            style: {
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            },
          }}
        >
          <IconButton onClick={handleDrawerToggle} style={{ position: 'absolute', top: 10, right: 10 }}>
            <CloseIcon style={{color: 'black'}} />
          </IconButton>
          <List style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <ListItem
              button
              component={Link}
              to="/"
              onClick={() => {
                setValue('/');
                handleDrawerToggle();
              }}
              style={{ width: '100%', justifyContent: 'center', fontSize: '25px' }}
            >
              Home
            </ListItem>
            <ListItem
            button
            style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center', fontSize: '25px' }}
        >
            <Box display="flex" alignItems="center">
                <Box 
                    component="span"
                    onClick={() => {navigate('/product'); handleDrawerToggle();}}
                >
                    Product
                </Box>
                <Box 
                    component="span" 
                    onClick={(e) => {
                        e.stopPropagation();
                        handleProductTabClick(e);}}>
                    {isProductDropdownOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </Box>
            </Box>
        </ListItem>
            <ListItem
              button
              component={Link}
              to="/showcase"
              onClick={() => {
                setValue('/showcase');
                handleDrawerToggle();
              }}
              style={{ width: '100%', justifyContent: 'center', fontSize: '25px' }}
            >
              Showcase
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/blogs"
              onClick={() => {
                setValue('/blogs');
                handleDrawerToggle();
              }}
              style={{ width: '100%', justifyContent: 'center', fontSize: '25px' }}
            >
              Blogs
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/contact"
              onClick={() => {
                setValue('/contact');
                handleDrawerToggle();
              }}
              style={{ width: '100%', justifyContent: 'center', fontSize: '25px' }}
            >
              Contact
            </ListItem>
            <ListItem
              button
              component="a"
              href="https://www.facebook.com/r.silathip"
              onClick={handleDrawerToggle}
              style={{ width: '100%', justifyContent: 'center' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon />
            </ListItem>
          </List>
          <Popover
            open={Boolean(productTabAnchorEl)}
            anchorEl={productTabAnchorEl}
            onClose={handleProductTabClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Paper>
              <List>
                <ListItem
                  button
                  component={Link}
                  to="/product/productlamp"
                  onClick={() => {
                    setValue('/product/productlamp');
                    handleDrawerToggle();
                    handleProductTabClose();
                  }}
                  style={{ fontSize: '20px' }}
                >
                  ตะเกียงหินญี่ปุ่น
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/product/producthomedeco"
                  onClick={() => {
                    setValue('/product/producthomedeco');
                    handleDrawerToggle();
                    handleProductTabClose();
                  }}
                  style={{ fontSize: '20px' }}
                >
                  หินตกแต่งสถานที่
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/product/productbasin"
                  onClick={() => {
                    setValue('/productbasin');
                    handleDrawerToggle();
                    handleProductTabClose();
                  }}
                  style={{ fontSize: '20px' }}
                >
                  อ่างหิน
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/product/productsculpture"
                  onClick={() => {
                    setValue('/product/productsculpture');
                    handleDrawerToggle();
                    handleProductTabClose();
                  }}
                  style={{ fontSize: '20px' }}
                >
                  งานประติมากรรมหิน
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/product/productboulder"
                  onClick={() => {
                    setValue('/product/productboulder');
                    handleDrawerToggle();
                    handleProductTabClose();
                  }}
                  style={{ fontSize: '20px' }}
                >
                  ป้ายหินแกะสลัก
                </ListItem>
                <ListItem
                  button
                  component={Link}
                  to="/product/productsurface"
                  onClick={() => {
                    setValue('/product/productsurface');
                    handleDrawerToggle();
                    handleProductTabClose();
                  }}
                  style={{ fontSize: '20px' }}
                >
                  งานกำแพง ผนัง และพื้นผิว
                </ListItem>
              </List>
              <IconButton onClick={handleProductTabClick} style={{ position: 'absolute', top: 10, right: 10 }}>
            </IconButton>
            </Paper>
          </Popover>
        </Drawer>
      )}
    </div>
  );
}

export default AppHeader;