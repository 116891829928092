import * as React from 'react';
import { Link } from 'react-router-dom';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ProductHeader from './ProductHeader';
import { Helmet } from 'react-helmet';

export default function Product() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const cols = isDesktop ? 2 : 1; 
  const imageSize = 250; 

  return (
    <div style={{marginTop: 60}}>
      <Helmet>
      <title>ผลิตภัณฑ์จากหินของเรา | ผลิตและจำหน่าย หินแกรนิต ครกหินอ่างศิลา</title>
      <meta name='description' content='บริษัท รุ่งเรืองศิลาทิพย์ จำกัด เราผลิตและจำหน่าย หินแกรนิต ครกหิน ครกหินอ่างศิลา ช้างหิน สิงห์หินแกรนิต กิเลนหินแกรนิต ปี่เซี๊ยะหินแกรนิต อ่างหิน ตะเกียงหิน หินจัดสวน หินมงคล ลูกนิมิตหินแกรนิต ศาลพระภูมิหินแกรนิต สะพานหิน หินตกแต่งบ้าน หินตกแต่งภายใน หินตกแต่งโรงแรม หินตกแต่งร้านอาหาร และงานหินแกะสลักทุกรูปแบบ ทั้งเล็กและใหญ่' />
      <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/rungreangsilatip.appspot.com/o/ProductImage%2Flatern%2Flatern03.jpg?alt=media&token=14ece851-0874-4a97-9074-20c67e6ea419" />
      </Helmet>
      <ProductHeader />
    <ImageList cols={cols} gap={8} sx={{ 
      width: '100%', 
      height: 'auto', 
      aspectRatio: 'auto',
      justifyContent: 'center',
      alignItems: 'center', 
      justifyItems: 'center',
      flexWrap: 'wrap',
      marginBottom: 10  }}>
      {itemData.map((item) => (
        <Link to={item.link} key={item.img} style={{ textDecoration: 'none', justifyContent: 'space-evenly' }}>
        <ImageListItem style={{ width: '400px', height: '250px', cursor: 'pointer' }}>
          <img
            srcSet={`${item.img}?w=${imageSize}&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.img}?w=${imageSize}&fit=crop&auto=format`}
            alt={item.title}
            loading="lazy"
            style={{
              height: '100%',
              width: '100%',
              objectFit: 'cover',
            }}
          />
          <ImageListItemBar
            title={item.title}
            subtitle={item.author}
            actionIcon={
              <IconButton
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.title}`}
              >
                <InfoIcon />
              </IconButton>
            }
          />
        </ImageListItem>
        </Link>
      ))}
    </ImageList>
    </div>
  );
}

const itemData = [
  {
    img: 'https://firebasestorage.googleapis.com/v0/b/rungreangsilatip.appspot.com/o/ProductImage%2Flatern%2Flatern10.jpg?alt=media&token=7e5d1e9d-bb31-4da7-bc59-4efa73425fa6&_gl=1*sv1h8f*_ga*MTMxMjAwODQyNS4xNjk3ODA2NTQ3*_ga_CW55HF8NVT*MTY5NzgyNjMzMi4zLjEuMTY5NzgyNjc0MS4zNy4wLjA.',
    title: 'ตะเกียงหินญี่ปุ่น',
    link: '/product/productlamp',
  },
  {
    img: 'https://firebasestorage.googleapis.com/v0/b/rungreangsilatip.appspot.com/o/ProductImage%2Fdecoration%2Fdeco01.jpg?alt=media&token=a1c09c15-8716-4ba6-9bbd-3715946820d2&_gl=1*19fkn5o*_ga*MTMxMjAwODQyNS4xNjk3ODA2NTQ3*_ga_CW55HF8NVT*MTY5NzgyNjMzMi4zLjEuMTY5NzgyNjc5NS40My4wLjA.',
    title: 'หินตกแต่งสถานที่',
    link: '/product/producthomedeco',
  },
  {
    img: 'https://firebasestorage.googleapis.com/v0/b/rungreangsilatip.appspot.com/o/ProductImage%2Fbasin%2Fbasin06.jpg?alt=media&token=9c126997-078f-49aa-8a79-9da000d553b0&_gl=1*z3z26y*_ga*MTMxMjAwODQyNS4xNjk3ODA2NTQ3*_ga_CW55HF8NVT*MTY5NzgyNjMzMi4zLjEuMTY5NzgyNjYxNS41NS4wLjA.',
    title: 'อ่างหิน',
    link: '/product/productbasin',
  },
  {
    img: 'https://firebasestorage.googleapis.com/v0/b/rungreangsilatip.appspot.com/o/ProductImage%2Fsculpture%2Fsculture11.jpg?alt=media&token=f1170c3a-1473-41ae-9845-cc77666bb4d4&_gl=1*ig05i7*_ga*MTMxMjAwODQyNS4xNjk3ODA2NTQ3*_ga_CW55HF8NVT*MTY5NzgyNjMzMi4zLjEuMTY5NzgyNjg5My40MC4wLjA.',
    title: 'งานประติมากรรมหิน',
    link: '/product/productsculpture',
  },
  {
    img: 'https://firebasestorage.googleapis.com/v0/b/rungreangsilatip.appspot.com/o/ProductImage%2Fboulder%2Fboulder07.jpg?alt=media&token=7a47933f-a4f8-4991-8fd3-7ef1e90d17b6&_gl=1*1wd3r97*_ga*MTMxMjAwODQyNS4xNjk3ODA2NTQ3*_ga_CW55HF8NVT*MTY5NzgyNjMzMi4zLjEuMTY5NzgyNjk4Mi40OS4wLjA.',
    title: 'ป้ายหินแกะสลัก',
    link: '/product/productboulder',
  },
  {
    img: 'https://firebasestorage.googleapis.com/v0/b/rungreangsilatip.appspot.com/o/ProductImage%2Fsurface%2Fsurface09.jpg?alt=media&token=3f21bb8d-ea8e-4c7e-87b2-c2a64b132cbb&_gl=1*1r83dj8*_ga*MTMxMjAwODQyNS4xNjk3ODA2NTQ3*_ga_CW55HF8NVT*MTY5NzgyNjMzMi4zLjEuMTY5NzgyNzAxOS4xMi4wLjA.',
    title: 'งานกำแพง ผนังและพื้นผิว',
    link: '/product/productsurface',
  },
];