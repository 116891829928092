import React from 'react';
import image1 from './images/cover/cover04.jpg'

function CoverImage() {
  const coverStyle = {
    width: '100%',
    height: '300px',
    backgroundImage: `url(${image1})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginBottom: '20px'
  };

  return <div style={coverStyle}></div>;
}

export default CoverImage;
