import React, { useState, useEffect } from 'react';
import { Button, TextField, Grid, Container, useMediaQuery, useTheme, Chip, CircularProgress } from '@mui/material';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { readAndCompressImage } from 'browser-image-resizer';
import { useNavigate, useParams } from 'react-router-dom';

function EditBlogForms() {
  const [loading, setLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [coverImage, setCoverImage] = useState(null);
  const [coverPreview, setCoverPreview] = useState(null);
  const [formData, setFormData] = useState({
    subject: '',
    writer: '',
    details: '',
    cover: '',
    images: []
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { blogId } = useParams();

  useEffect(() => {
    const fetchBlogData = async () => {
      setLoading(true);
      const docRef = doc(db, 'blogs', blogId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setFormData(data);
        setCoverPreview(data.cover);
        setSelectedImages(data.images);
      } else {
        console.log("No such document!");
      }
      setLoading(false);
    };

    fetchBlogData();
  }, [blogId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const remainingImagesCount = 8 - selectedImages.length;

  const handleCoverChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const imageConfig = {
      quality: 0.5,
      maxWidth: 800,
      maxHeight: 800,
      autoRotate: true,
      debug: true
    };

    try {
      const resizedImage = await readAndCompressImage(file, imageConfig);
      setCoverImage(resizedImage);

      const reader = new FileReader();
      reader.onloadend = () => {
        setCoverPreview(reader.result);
      };
      reader.readAsDataURL(resizedImage);
    } catch (error) {
      console.error("Error resizing the image: ", error);
    }
  };

  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length + selectedImages.length > 8) {
      alert('เลือกได้สูงสุด 8 ภาพ');
      return;
    }
    if (files.length > remainingImagesCount) {
      alert(`คุณสามารถเลือกได้ ${remainingImagesCount} รูปภาพ`);
      return;
    }

    const processedImages = [];
    const imagePreviews = [];

    for (let file of files) {
      const imageConfig = {
        quality: 0.5,
        maxWidth: 800,
        maxHeight: 800,
        autoRotate: true,
        debug: true
      };

      try {
        const resizedImage = await readAndCompressImage(file, imageConfig);
        processedImages.push(resizedImage);

        const reader = new FileReader();
        reader.onloadend = () => {
          imagePreviews.push(reader.result);
          setSelectedImages([...selectedImages, ...imagePreviews]);
        };
        reader.readAsDataURL(resizedImage);
      } catch (error) {
        console.error("Error resizing the image: ", error);
      }
    }

    setFormData(prevState => ({
      ...prevState,
      images: [...prevState.images, ...processedImages]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    const storage = getStorage();
    let updatedCoverURL = formData.cover; 
  
    if (coverImage) {
      const coverImageName = 'cover_' + Math.random().toString(36).substring(2, 15) + ".jpg";
      const coverStorageRef = ref(storage, 'blogs/' + coverImageName);
      const coverUploadTask = uploadBytesResumable(coverStorageRef, coverImage);
  
      try {
        await new Promise((resolve, reject) => {
          coverUploadTask.on('state_changed',
            () => {},
            (error) => {
              console.error("Error uploading cover image: ", error);
              reject(error);
            }, 
            async () => {
              updatedCoverURL = await getDownloadURL(coverUploadTask.snapshot.ref);
              resolve();
            }
          );
        });
      } catch (error) {
        console.error("Error while uploading new cover image:", error);
        alert("Failed to upload new cover image.");
        setLoading(false);
        return;
      }
    }
  
    const downloadURLs = formData.images.map(image => (typeof image === 'string' ? image : null)).filter(url => url != null);
  
    for (let image of formData.images) {
      if (typeof image !== 'string') {
        const imageName = Math.random().toString(36).substring(2, 15) + ".jpg";
        const storageRef = ref(storage, 'blogs/' + imageName);
        const uploadTask = uploadBytesResumable(storageRef, image);
  
        try {
          const downloadURL = await new Promise((resolve, reject) => {
            uploadTask.on('state_changed', 
              () => {},
              (error) => {
                console.error("Error uploading image: ", error);
                reject(error);
              }, 
              async () => {
                resolve(await getDownloadURL(uploadTask.snapshot.ref));
              }
            );
          });
          downloadURLs.push(downloadURL);
        } catch (error) {
          console.error("Error while uploading additional images:", error);
          alert("Failed to upload one or more images.");
          setLoading(false);
          return; 
        }
      }
    }
  
    const docRef = doc(db, 'blogs', blogId);
    try {
      await updateDoc(docRef, {
        ...formData,
        cover: updatedCoverURL,
        images: downloadURLs
      });
  
      console.log("Document successfully updated!");
      setSelectedImages([]);
      setCoverImage(null);
      setCoverPreview(null);
      alert("แก้ไขบทความเรียบร้อยแล้ว");
      navigate('/blogeditor');
    } catch (error) {
      console.error("Error updating document: ", error);
      alert("There was an error submitting the form. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  const handleDeselectImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);

    const updatedFormDataImages = [...formData.images];
    updatedFormDataImages.splice(index, 1);
    setFormData(prevState => ({
        ...prevState,
        images: updatedFormDataImages  
    }));
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
    }
  };
  return (
    <Container maxWidth={isMobile ? 'xl' : 'lg'}>
      <form onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              fullWidth
              required
              label="หัวเรื่อง"
              name="subject"
              variant="outlined"
              value={formData.subject}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              required
              label="บทความ"
              name="details"
              variant="outlined"
              size="medium"
              multiline
              rows={20}
              inputProps={{ maxLength: 10000 }}
              value={formData.details}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              sx={{ "& .MuiInputBase-input": { fontSize: 15, padding: 1 } }}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              required
              label="ผู้เขียนบทความ"
              name="writer"
              variant="outlined"
              value={formData.writer}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', position: 'relative' }}>
              <input
                id="coverInput"
                type="file"
                onChange={handleCoverChange}
                style={{
                  opacity: 0,
                  position: 'absolute',
                  zIndex: 1,
                  width: '100%',
                  height: '100%',
                }}
              />
              <label
                htmlFor="coverInput"
                style={{
                  display: 'block',
                  padding: '10px 15px',
                  background: 'black',
                  color: 'white',
                  cursor: 'pointer',
                  borderRadius: '5px',
                }}
              >
                อัปโหลดภาพหน้าปก
              </label>
            </div>
            {coverPreview && (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 5 }}>
                <img src={coverPreview} alt="Cover Preview" style={{ width: '100px', height: '100px', marginBottom: 5 }} />
                <Chip label="ลบ" variant="filled" onClick={() => { setCoverImage(null); setCoverPreview(null); }} />
              </div>
            )}
          </Grid>
          <Grid item>
            <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', position: 'relative' }}>
              <input
                id="imageInput"
                type="file"
                multiple
                onChange={handleImageChange}
                style={{
                  opacity: 0,
                  position: 'absolute',
                  zIndex: 1,
                  width: '100%',
                  height: '100%',
                }}
              />
              <label
                htmlFor="imageInput"
                style={{
                  display: 'block',
                  padding: '10px 15px',
                  background: 'black',
                  color: 'white',
                  cursor: 'pointer',
                  borderRadius: '5px',
                }}
              >
                เลือกได้ {remainingImagesCount} รูปภาพ
              </label>
            </div>
            {selectedImages.map((image, index) => (
              <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 5 }}>
                <img src={typeof image === 'string' ? image : URL.createObjectURL(image)} alt={`Selected Preview ${index}`} style={{ width: '100px', height: '100px', marginBottom: 5 }} />
                <Chip label="ลบ" variant="filled" onClick={() => handleDeselectImage(index)} />
              </div>
            ))}
          </Grid>
          <Grid item>
            {loading && (
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <CircularProgress />
              </div>
            )}
          </Grid>
          <Grid item>
            <Button variant="outlined" color="inherit" type="submit" disabled={loading}>
              แก้ไขบทความ
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
    );
};

export default EditBlogForms;