import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig';
import { Button, Box, useTheme } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline'; 
import EditIcon from '@mui/icons-material/Edit'; 
import useMediaQuery from '@mui/material/useMediaQuery';

function Dashboard() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/loginadmin');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  return (
    <div style={{ marginTop: 100, display: "flex", alignItems: 'center', flexDirection: 'column' }}>
      <h2 style={{marginBottom: 50}}>Dashboard</h2>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isDesktop ? 'row' : 'column',
          gap: 4, 
        }}
      >
        <Button
          startIcon={<MailOutlineIcon />}
          variant="contained"
          color="primary"
          onClick={() => navigate('/inbox')}
        >
          INBOX
        </Button>
        <Button
          startIcon={<EditIcon />}
          variant="contained"
          color="secondary"
          onClick={() => navigate('/blogeditor')}
        >
          Blog Editor
        </Button>
      </Box>
    </div>
  );
}

export default Dashboard;
