import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyC8A3jwHm5FJcY4zLmFeCwO3Ebzkhn1Q6Q",
  authDomain: "rungreangsilatip.firebaseapp.com",
  projectId: "rungreangsilatip",
  storageBucket: "rungreangsilatip.appspot.com",
  messagingSenderId: "510678904260",
  appId: "1:510678904260:web:1057c984957947fd778891"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { db, storage, auth, analytics };