import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebaseConfig";
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Helmet } from 'react-helmet';

function BlogView() {
    const [blog, setBlog] = useState(null);
    const { id } = useParams();
 
    useEffect(() => {
        const fetchBlog = async () => {
          const BlogRef = doc(db, 'blogs', id);
          const BlogDoc = await getDoc(BlogRef);
          if (BlogDoc.exists()) {
            setBlog({ id: BlogDoc.id, ...BlogDoc.data() });
          } else {
            console.error("Blog does not exist");
          }
        };
    
        fetchBlog();
      }, [id]);
    
      if (!blog) return <div>Loading...</div>;

  return (
    <div style={{marginTop: 80, padding: 15}}>
      <Helmet>
<html lang="en">
  <head>
    <meta/>
    <title>{blog.subject} | ผลิตและจำหน่าย หินแกรนิต ครกหินอ่างศิลา</title>
      <meta name='description' content='บริษัท รุ่งเรืองศิลาทิพย์ จำกัด เราผลิตและจำหน่าย หินแกรนิต ครกหิน ครกหินอ่างศิลา ช้างหิน สิงห์หินแกรนิต กิเลนหินแกรนิต ปี่เซี๊ยะหินแกรนิต อ่างหิน ตะเกียงหิน หินจัดสวน หินมงคล ลูกนิมิตหินแกรนิต ศาลพระภูมิหินแกรนิต สะพานหิน หินตกแต่งบ้าน หินตกแต่งภายใน หินตกแต่งโรงแรม หินตกแต่งร้านอาหาร และงานหินแกะสลักทุกรูปแบบ ทั้งเล็กและใหญ่' />
      <meta property="og:image" content={blog.cover} />
  </head>
</html>
      </Helmet>
      <div style={{display: 'flex', textAlign: 'center', justifyContent: 'center', flexDirection: 'column'}}>
        <p style={{fontWeight: 900}}>   
          {blog.subject}
        </p>
        <div>
          <p style={{ whiteSpace: 'pre-line' }}>
            {blog.details}
          </p>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding: 10, marginTop: 10, marginBottom: 50 }}>
      <Box sx={{ width: 800, height: "100%"}}>
        <ImageList variant="masonry" cols={2} gap={8} >
        {blog.images && blog.images.map((imgUrl, idx) => (
                       <ImageListItem key={idx} >
                       <img
                         srcSet={`${imgUrl}?w=248&fit=crop&auto=format&dpr=2 2x`}
                         src={`${imgUrl}?w=248&fit=crop&auto=format`}
                         loading="lazy"
                         alt='blog'
                       />
                     </ImageListItem>
        ))}
        </ImageList>
      </Box>
      </div>
      <div style={{display: 'flex', textAlign: 'center', justifyContent: 'center', flexDirection: 'column'}}>
        <p>ผู้เขียน: {blog.writer}</p>
        <p>{blog.timestamp.toDate().toLocaleString()}</p>
      </div>
    </div>
  );
}

export default BlogView;