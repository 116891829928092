import React, {useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig';
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebaseConfig";

function InboxDetail() {
  const { id } = useParams();
  const [contact, setContact] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/loginadmin');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const fetchContact = async () => {
      const contactRef = doc(db, 'contacts', id);
      const contactDoc = await getDoc(contactRef);
      if (contactDoc.exists) {
        setContact({ id: contactDoc.id, ...contactDoc.data() });
      } else {
        console.error("Contact does not exist");
      }
    };

    fetchContact();
  }, [id]);

  if (!contact) return <div>Loading...</div>;

  return (
    <div style={{padding: 20, marginTop: 80}}>
      <button 
        onClick={() => navigate(-1)} 
      >
        Go Back
      </button>
      
      <h1>{contact.subject}</h1>
      <p><strong>ชื่อผู้ติดต่อ:</strong> {contact.name}</p>
      <p><strong>อีเมล:</strong> {contact.email}</p>
      <p><strong>เบอร์ติดต่อ:</strong> {contact.phone}</p>
      <p><strong>รายละเอียด:</strong> {contact.details}</p>
      <div>
        {contact.images.map((imgUrl, idx) => (
          <img key={idx} src={imgUrl} alt="Uploaded content" style={{width: '100%'}}/>
        ))}
      </div>
    </div>
  );
}

export default InboxDetail;
