import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import './fonts.css'

function Footer() {
    return (
      <Box 
        sx={{ 
          backgroundColor: 'black', 
          color: 'white', 
          padding: '10px 0',
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
        }}
      >
        <Container>
          <Typography variant="body1" align="center">
            ©2024 Rungruang Silathip Co., Ltd.
          </Typography>
        </Container>
      </Box>
    );
}

export default Footer;