import React from 'react';
import Product1 from './product1';
import ScrollToTopButton from './scrollToTopButton';
import { Helmet } from 'react-helmet';

function ProductLamp() {
  return (
    <div style={{padding: 20, marginBottom: 50}}>
            <Helmet>
      <title>ตะเกียงหิน ตะเกียงหินญี่ปุ่น หินแต่งสวน | ผลิตและจำหน่าย หินแกรนิต ครกหินอ่างศิลา</title>
      <meta name='description' content='บริษัท รุ่งเรืองศิลาทิพย์ จำกัด เราผลิตและจำหน่าย หินแกรนิต ครกหิน ครกหินอ่างศิลา ช้างหิน สิงห์หินแกรนิต กิเลนหินแกรนิต ปี่เซี๊ยะหินแกรนิต อ่างหิน ตะเกียงหิน หินจัดสวน หินมงคล ลูกนิมิตหินแกรนิต ศาลพระภูมิหินแกรนิต สะพานหิน และงานหินแกะสลักทุกรูปแบบ ทั้งเล็กและใหญ่' />
      <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/rungreangsilatip.appspot.com/o/ProductImage%2Flatern%2Flatern11.jpg?alt=media&token=19a1a3ee-437d-403d-8589-027f87d20bf8" />
      </Helmet>
      <div style={{marginBottom: 80, marginTop: 40, display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
      <h3 style={{fontWeight: 200}}>ตะเกียงหินญี่ปุ่น</h3>
      <Product1 />
      </div>
      <div style={{
    marginTop: 100,
    marginBottom: 50,
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center',
    textAlign: 'center', 
    flexDirection: 'column',
    padding: 20
    }}>
      <h1>
        ติดต่อเรา
      </h1>
      <h3>
        บริษัท รุ่งเรืองศิลาทิพย์ จำกัด
      </h3>
      <p>ที่อยู่: 9/76 ตำบลเสม็ด อำเภอเมืองชลบุรี จังหวัดชลบุรี 20000</p>
      <p>อีเมล: silathip2013@gmail.com</p>
      <h4>
      โทรศัพท์: 038-398114, 096-291-9962
      </h4>
      <h4 
  style={{
    backgroundColor: 'black', 
    color: 'white', 
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,  
    borderRadius: 10,
    cursor: 'pointer'
  }}
  onClick={() => {
    window.open('https://lin.ee/jLB82AU', '_blank');
  }}
>
  LINE
</h4>
    </div>
    <ScrollToTopButton />
    </div>
  );
}

export default ProductLamp;
