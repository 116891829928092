import React from 'react';
import { Grid, Typography } from '@mui/material';
import image1 from './images/Picture10.jpg';
import image2 from './images/Picture9.jpg';

function Body4() {
    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 20,
        paddingLeft: 20, 
        paddingRight: 20,
        marginBottom: 50
    };

    const gridContainerStyle = {
        maxWidth: '600px', 
    };

    const imageStyle = {
        width: '100%',
        paddingTop: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
    };

    return (
        <div style={containerStyle}>
            <Grid container spacing={3} style={gridContainerStyle}>
                <Grid item xs={6}>
                    <div style={{ ...imageStyle, backgroundImage: `url(${image1})` }} alt="Description 1"></div>
                </Grid>
                <Grid item xs={6}>
                    <div style={{ ...imageStyle, backgroundImage: `url(${image2})` }} alt="Description 2"></div>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" align="center" style={{textAlign: 'center', marginBottom: 15}}>
ประเทศไทยจังหวัดใดมีครกหินที่มีชื่อเสียงและประวัติยาวนาน? คำตอบคือจังหวัดชลบุรี <strong> "ครกหินอ่างศิลา" </strong>
                    </Typography>
                    <Typography variant="body2" align="center" style={{textAlign: 'center', marginBottom: 15}}>
ครกหิน เป็นเครื่องมือในการบดหรือทำให้วัตถุดิบเป็นสารละเอียด ซึ่งมีการใช้มาตั้งแต่สมัยโบราณ แต่สิ่งที่ทำให้ครกหินจากจังหวัดชลบุรี และเฉพาะอย่างยิ่งครกหินอ่างศิลา มีชื่อเสียงเป็นอย่างยิ่งคือ การเลือกใช้วัตถุดิบที่มีคุณภาพ การผลิตที่ละเอียดอ่อน และการส่งมอบความรู้เรื่องการผลิตครกหินจากคนรุ่นหนึ่งสู่รุ่นต่อไป
<strong>ช่างครกหินจากอ่างศิลา ได้สร้างชื่อเสียงให้กับจังหวัดด้วยความสามารถที่พิเศษ </strong>การเลือกหินที่มีคุณภาพดี ทำให้ครกหินจากที่นี่เป็นที่รู้จักและประสบความสำเร็จในหลายประเทศทั่วโลก
                    </Typography >
                    <Typography variant="body2" align="center" style={{textAlign: 'center', marginBottom: 15}}>
                    ไม่เพียงแต่จะเป็นเครื่องมือที่มีประสิทธิภาพ ครกหินอ่างศิลายังเป็นศิลปะที่แสดงถึงวัฒนธรรมและประวัติศาสตร์ของพื้นที่ วิธีการผลิตที่ยังคงความดั้งเดิม ทำให้ครกหินจากอ่างศิลาเป็นที่รักและนับถือของคนทั้งในและต่างประเทศ
ในยุคปัจจุบันที่เครื่องมือทางครัวมีการพัฒนาอย่างรวดเร็ว ครกหินอ่างศิลายังคงมีที่ยืนยันในใจของคนรักการทำอาหาร เนื่องจากคุณภาพที่ยังคงเป็นที่รู้จักมาตลอดกาล
                    </Typography>
                    <Typography variant="body2" align="center" style={{textAlign: 'center', marginBottom: 15}}>
และเมื่อพูดถึงครกหินจากจังหวัดชลบุรี <strong>คำว่า "อ่างศิลา" </strong>ก็คือคำที่ไม่สามารถลืมเลือน ซึ่งเป็นสัญลักษณ์ของความภาคภูมิใจ และความยิ่งใหญ่ของจังหวัดชลบุรีและของเรา
                    </Typography>
                    <Typography variant="body2" align="center" style={{textAlign: 'center', marginBottom: 15}}>
<strong>บริษัท รุ่งเรืองศิลาทิพย์ จำกัด</strong>
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export default Body4;
