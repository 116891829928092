import React, { useState } from 'react';
import { Button, TextField, Grid, Container, useMediaQuery, useTheme } from '@mui/material';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from './firebaseConfig'
import { readAndCompressImage } from 'browser-image-resizer';
import "./fonts.css";

function ContactForm() {
  const [loading, setLoading] = useState(false);
  const fileInputRef = React.useRef(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    details: '',
    images: []
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const remainingImagesCount = 5 - selectedImages.length;

  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length + selectedImages.length > 5) {
      alert('เลือกได้สูงสุด 5 ภาพ');
      return;
  }
  if (files.length > remainingImagesCount) {
    alert(`คุณสามารถเลือกได้ ${remainingImagesCount} รูปภาพ`);
    return;
}
    
    const processedImages = [];
    const imagePreviews = [];

    for (let file of files) {
      const imageConfig = {
        quality: 0.5,
        maxWidth: 800,
        maxHeight: 800,
        autoRotate: true,
        debug: true
      };

      try {
        const resizedImage = await readAndCompressImage(file, imageConfig);
        processedImages.push(resizedImage);

        const reader = new FileReader();
        reader.onloadend = () => {
          imagePreviews.push(reader.result);
          setSelectedImages([...selectedImages, ...imagePreviews]);
        };
        reader.readAsDataURL(resizedImage);
      } catch (error) {
        console.error("Error resizing the image: ", error);
      }
    }

    setFormData(prevState => ({
      ...prevState,
      images: [...prevState.images, ...processedImages]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const storage = getStorage();
    const downloadURLs = [];

    for (let image of formData.images) {
      const imageName = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15) + ".jpg";
      const storageRef = ref(storage, 'contacts/' + imageName);
      const uploadTask = uploadBytesResumable(storageRef, image);

      await new Promise((resolve, reject) => {
        uploadTask.on('state_changed', 
          () => {},
          (error) => {
            console.error("Error uploading image: ", error);
            reject(error);
          }, 
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            downloadURLs.push(downloadURL);
            resolve();
          }
        );
      });
    }

    const contactsRef = collection(db, 'contacts');
    try {
      await addDoc(contactsRef, {
        ...formData,
        images: downloadURLs,
        timestamp: serverTimestamp()
      });

      console.log("Document successfully written!");
      setFormData({
        name: '',
        email: '',
        phone: '',
        subject: '',
        details: '',
        images: []
      });
      setSelectedImages([]);
      alert("ขอบคุณที่สนใจสินค้าและบริการของเรา เราจะติดต่อกลับโดยเร็วที่สุด");
    } catch (error) {
      console.error("Error writing document: ", error);
      alert("There was an error submitting the form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDeselectImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    setSelectedImages(updatedImages);

    const updatedFormDataImages = [...formData.images];
    updatedFormDataImages.splice(index, 1);
    setFormData(prevState => ({
        ...prevState,
        images: updatedFormDataImages  
    }));

    if (fileInputRef.current) {
        fileInputRef.current.value = '';
    }
};

  return (
    <Container maxWidth={isMobile ? 'xl' : 'lg'}>
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" spacing={2} >
          <Grid item width={isMobile ? "350px" : "500px"}>
            <TextField
              fullWidth
              required
              label="ชื่อผู้ติดต่อ"
              name="name"
              variant="outlined"
              value={formData.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              required
              label="อีเมล"
              name="email"
              variant="outlined"
              value={formData.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              required
              label="เบอร์โทรศัพท์"
              name="phone"
              variant="outlined"
              value={formData.phone}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              required
              label="หัวข้อ"
              name="subject"
              variant="outlined"
              value={formData.subject}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              required
              label="รายละเอียด"
              name="details"
              variant="outlined"
              multiline
              rows={6}
              value={formData.details}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
          <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', position: 'relative' }}>
    <input
        id="imageInput"
        type="file"
        multiple
        onChange={handleImageChange}
        ref={fileInputRef}
        style={{
            opacity: 0,
            position: 'absolute',
            zIndex: 1,
            width: '100%',
            height: '100%'
        }}
    />
    <label htmlFor="imageInput" style={{
        display: 'block',
        padding: '10px 15px',
        background: 'black',
        color: 'white',
        cursor: 'pointer',
        borderRadius: '5px'
    }}>
        เลือกได้ {remainingImagesCount} รูปภาพ
    </label>

</div>
         {selectedImages.map((image, index) => (
    <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 5 }}>
        <img src={image} alt={`Selected Preview ${index}`} style={{ width: '100px', height: '100px', marginBottom: 5 }} />
        <Chip label="ลบ" variant="filled" onClick={() => handleDeselectImage(index)} />
    </div>
))}
          </Grid>
          <Grid item>
   {loading && (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      <CircularProgress />
    </div>
  )}
</Grid>
<Grid item>
  <Button variant="outlined" color="inherit" type="submit" disabled={loading}>
    ส่ง
  </Button>
</Grid>
        </Grid>
      </form>
    </Container>
  );
}

export default ContactForm;
